<script>
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: {
        index: {
            default: -1,
        },
        id: {
            required: true,
            type: String,
        },
        displayName: {
            required: true,
            type: String,
        },
        value: {

        },
        options: {
            required: true,
            default() {
                return [];
            }
        },
        error: [],

        hideLabel: {
            type: Boolean,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        onChanged: {

        },
        onInitial: {

        },
    },

    data() {
        return {
            oldSelected: null,
            selected: null,
        };
    },

    created() {
        
    },

    mounted() {
        this.select(this.value);
        if (this.onInitial) {
            this.onInitial(this.index);
        }
    },

    watch: {
        value(newValue) {
            this.select(newValue);
        },

        options(newValue) {
            if (newValue && newValue !== null) {
                this.select(this.value);
            }
        },

        selected(newValue) {
            if (newValue !== null) {
                if (this.value != newValue.value) {
                    this.handleInput(newValue.value);
                }
            } else {
                if (this.value !== null) {
                    this.handleInput(null);
                }
            }
        },
    },

    methods: {
        select(newValue) {
            if (newValue !== null) {
                if (this.selected !== null) {
                    if (this.selected.value !== newValue) {
                        this.selectItem(newValue);
                    }
                } else {
                    this.selectItem(newValue);
                }
            } else {
                if (this.selected !== null) {
                    this.selected = null;
                }
            }
        },

        selectItem(newValue) {
            this.selected = this.getSelectionItem(this.options, newValue);
        },

        handleInput(value) {
            this.$emit('input', value);
        },

        changed() {
            if (this.onChanged) {
                const oldValue = this.oldSelected !== null ? this.oldSelected.value : null;
                const newValue = this.selected !== null ? this.selected.value : null;
                this.onChanged(newValue, oldValue, this.index);
            }
            this.oldSelected = this.selected;
        },
    }
};
</script>

<template>
    <div class="form-group">
        <div>
            <label :for="id" v-if="hideLabel === false">{{ displayName }}</label>
            <multiselect class="pl-0 pr-0" v-model="selected" :options="options" :multiple="false" :disabled="readOnly"
                :placeholder="`Select ${displayName}`" :searchable="true" label="name" track-by="value" @input="changed">
            </multiselect>
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="error && error.length > 0">
            {{ error[0] }}
        </div>
    </div>
</template>